import { URL_DEEP_LINK } from '@/constants/invoice'

const urlInvoicePortal = process.env.VUE_APP_INVOICE_PORTAL_URL || ''
export default [
  {
    title: 'Agency Balance',
    icon: 'DollarSignIcon',
    route: 'apps-flights-agencyBalance',
    visibleFloors: ['F1'],
    visibleRoles: ['KTT', 'ADM'],
  },
  {
    title: 'Flight v2',
    icon: 'NavigationIcon',
    route: 'apps-flightsV2-page',
    action: 'booking.searchBooking',
  },
  {
    title: 'Reservations',
    icon: 'BriefcaseIcon',
    action: 'booking.listBooking',
    route: 'apps-reservations-list',
    children: [
      {
        title: 'HOLD',
        route: 'apps-reservations_hold-list',
      },
      {
        title: 'PAID',
        route: 'apps-reservations_paid-list',
      },
      {
        title: 'CANCEL',
        route: 'apps-reservations_cancel-list',
      },
      {
        title: 'EXPIRED',
        route: 'apps-reservations_expired-list',
      },
      {
        title: 'reservation.viewEticket',
        route: 'apps-findEticket',
      },
      {
        title: 'reservation.findBookingVJ.title',
        route: 'apps-findBookingVJ',
        visibleFloors: ['F1'],
      },
      {
        title: 'reservation.bookingStatistic.title',
        route: 'apps-bookings-statistic',
        visibleFloors: ['F1'],
        visibleRoles: ['OPE', 'ADM', 'KTT'],
      },
      {
        title: 'reservation.pastDayBooking.title',
        route: 'apps-past-day-booking-list',
        // visibleFloors: ['F1', 'F2'],
      },
    ],
  },
  {
    title: 'reservation.findBookingByPnrCode',
    route: 'apps-findByPnrCode',
    icon: 'SearchIcon',
  },
  {
    title: 'Retail',
    icon: 'ShoppingCartIcon',
    visibleFloors: ['F2'],
    visibleRoles: ['OPE', 'ADM'],
    children: [
      {
        title: 'Retail Report',
        route: 'apps-retailGeneral',
      },
      {
        title: 'Retail Bookings',
        route: 'apps-retailBookings',
      },
      {
        title: 'Account Payable Retail',
        route: 'apps-retailAccountsPayable-list',
      },
      {
        title: 'Sale Report Retail',
        route: 'apps-retailSaleReports',
      },
      {
        title: 'Retail Config Service Fee',
        route: 'apps-retailConfigsServiceFee',
      },
    ],
  },
  {
    title: 'Check-in online',
    children: [
      {
        title: 'Vietjet Air',
        href: 'https://www.vietjetair.com/vi/checkin',
        image:
          'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/VJ.png',
      },
      {
        title: 'Bamboo Airways',
        href: 'https://digital.bambooairways.com/ssci/identification?lang=vi',
        image:
          'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/QH.png',
      },
      {
        title: 'Vietravel Airlines',
        href: 'https://booking.vietravelairlines.com/vi/checkin',
        image:
          'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/VU.png',
      },
      {
        title: 'Check-in VNA',
        href: 'https://dxcheckin.vietnamairlines.com/dx/VNCI/#/check-in/start?locale=vi-VN',
        image:
          'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/VN.png',
      },
      {
        title: 'Check-in cancellation VNA',
        href: 'https://www.vietnamairlines.com/vn/vi/Home?type=managebooking',
        image:
          'https://airdata-cms.sgp1.digitaloceanspaces.com/airlines/VN.png',
      },
    ],
  },
  {
    title: 'Flight Preparation',
    icon: 'FileIcon',
    route: 'apps-flight-preparation-page',
  },
  // {
  //   title: 'reservation.lowFareTracking.title',
  //   icon: 'MonitorIcon',
  //   route: 'apps-low-fare-tracking-statistic',
  //   visibleFloors: ['F1', 'F2'],
  //   // hideForProduction: true,
  // },
  {
    title: 'Terminals',
    icon: 'TerminalIcon',
    route: 'apps-terminals',
    visibleFloors: ['F1'],
    visibleRoles: ['ADM', 'OPE', 'BE'],
  },
  {
    title: 'Package configs',
    icon: 'PackageIcon',
    visibleFloors: ['F1', 'F2'],
    visibleRoles: ['ADM', 'OPE'],
    // hideForProduction: true,
    children: [
      {
        title: 'Package configs',
        route: 'apps-packageConfigs-list',
      },
      {
        title: 'Agency package configs',
        route: 'apps-agencyPackageConfigs-list',
      },
      {
        title: 'Agency package history',
        route: 'apps-agencyPackageHistory-list',
      },
      {
        title: 'Agency package order',
        route: 'apps-agencyPackageOrder-list',
      },
      {
        title: 'Package Coupons',
        route: 'apps-packageCoupon-list',

        visibleFloors: ['F1'],
      },
    ],
  },
  // {
  //   title: 'Documentations',
  //   icon: 'FileIcon',
  //   // action: '',
  //   children: [
  //     {
  //       title: 'Airlines 1G can pay',
  //       route: 'apps-airlines1gCanPay',
  //     },
  //   ],
  // },
  {
    title: 'Documentations',
    icon: 'FolderIcon',
    children: [
      {
        title: 'officialDispatch',
        route: 'apps-documents-folder-view',
        // visibleFloors: ['F1', 'F2'],
      },
      {
        title: 'Airlines 1G can pay',
        route: 'apps-airlines1gCanPay',
        visibleFloors: ['F1', 'F2'],
      },
    ],
  },
  {
    title: 'Work Schedule',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'schedule.agencyWorkSchedule',
        route: 'apps-parent-workSchedules-page',
        visibleFloors: ['F2', 'F3'],
      },
      {
        title: 'schedule.workSchedule',
        route: 'apps-workSchedules-page',
        visibleFloors: ['F1', 'F2'],
      },
    ],
  },
  {
    title: 'Customer',
    icon: 'UserIcon',
    route: 'apps-customers-list',
    action: 'customer.listCustomer',
  },
  {
    title: 'Employee',
    icon: 'UsersIcon',
    route: 'apps-employees-list',
    action: 'employee.listEmployee',
  },
  {
    title: 'Agency',
    icon: 'ArchiveIcon',
    route: 'apps-agencies-list',
    visibleFloors: ['F1', 'F2'],
  },

  // {
  //   title: 'Registers',
  //   icon: 'ArchiveIcon',
  //   route: 'apps-reg-list',
  // },
  {
    title: 'titleRepicient',
    icon: 'DownloadIcon',
    route: 'apps-topupRecipient-list',
    action: 'topup.listTopup',
    children: [
      {
        title: 'pendingPayment',
        route: 'apps-topupRecipient_pending-list',
      },
      {
        title: 'done',
        route: 'apps-topupRecipient_done-list',
      },
    ],
    visibleFloors: ['F1', 'F2'],
  },
  {
    title: 'titleSender',
    icon: 'UploadIcon',
    route: 'apps-topupSender-list',
    action: 'topup.listTopup',
    children: [
      {
        title: 'pendingPayment',
        route: 'apps-topupSender_pending-list',
      },
      {
        title: 'done',
        route: 'apps-topupSender_done-list',
      },
    ],
    visibleFloors: ['F3', 'F2'],
  },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    action: 'invoice.listInvoice',
    route: 'apps-invoiceList',
    visibleFloors: ['F1', 'F2'],
    hideForProduction: true,
    children: [
      {
        title: 'Invoice List',
        icon: 'FileTextIcon',
        route: 'apps-invoiceList',
      },
      {
        title: 'Invoice Create',
        icon: 'FilePlusIcon',
        route: 'apps-invoiceCreate',
      },
      {
        title: 'Release Registration',
        icon: 'ApertureIcon',
        children: [
          {
            title: 'Declaration',
            disabled: true,
            href: `${urlInvoicePortal}${URL_DEEP_LINK.DECLARATION}`,
          },
          {
            title: 'Authorization Declaration',
            disabled: true,
            href: `${urlInvoicePortal}${URL_DEEP_LINK.AUTHORIZATION_DECLARATION}`,
          },
          {
            title: 'Invoice Template',
            href: `${urlInvoicePortal}${URL_DEEP_LINK.INVOICE_TEMPLATE_MANAGE}`,
          },
        ],
      },
      {
        title: 'Certificate Manage',
        icon: 'EditIcon',
        href: `${urlInvoicePortal}${URL_DEEP_LINK.CERTIFICATE_MANAGE}`,
      },
    ],
  },
  // {
  //   title: 'Invoice old',
  //   icon: 'FileTextIcon',
  //   action: 'invoice.listInvoice',
  //   visibleFloors: ['F1', 'F2'],
  //   hideForProduction: true,
  //   children: [
  //     {
  //       title: 'Invoice',
  //       icon: 'DollarSignIco',
  //       route: 'apps-invoicesOld-list',
  //     },
  //     {
  //       title: 'Error Notification',
  //       icon: 'ShoppingBagIcon',
  //       route: 'apps-error-noti-list',
  //     },
  //   ],
  // },
  {
    title: 'Accounts payable',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Accounts payable general',
        // icon: 'DollarSignIcon',
        route: 'apps-accountsPayable-list',
        action: 'acc_payable.readAccpayble',
      },
      {
        title: 'Sale Report',
        // icon: 'ShoppingBagIcon',
        route: 'apps-saleReport',
        action: 'sales_report.readSalereport',
      },

      {
        title: 'Sale Report Refund Ticket',
        // icon: 'ShoppingBagIcon',
        route: 'apps-saleReportRefundTicket',
        action: 'sales_report.readSalereport',
      },
      {
        title: 'overPercentage',
        route: 'apps-agencies_warning_over_percentage-list',
        visibleRoles: ['KTT', 'KTV'],
      },
    ],
  },
  {
    title: 'Resell Ticket',
    icon: 'ShoppingCartIcon',
    visibleFloors: ['F1', 'F2'],
    children: [
      {
        title: 'Search resell Ticket',
        icon: 'SearchIcon',
        route: 'apps-resell-ticket',
      },
      {
        title: 'Manager resell Ticket',
        icon: 'SlidersIcon',
        route: 'apps-manager-resell-ticket',
      },
    ],
  },
  {
    title: 'Promotion',
    icon: 'PercentIcon',
    // action: 'promotionAg.listPromotion',
    visibleFloors: ['F1', 'F2'],
    children: [
      {
        title: 'Promotion AG',
        route: 'apps-promotionAg-list',
      },
      {
        title: 'Promotion Airline',
        route: 'apps-promotionAirline-list',
        visibleFloors: ['F1'],
      },
      {
        title: 'Promotion User',
        route: 'apps-promotionUser-list',
        visibleFloors: ['F1'],
        visibleRoles: ['OPE', 'ADM'],
      },
    ],
  },
  {
    title: 'Utils',
    icon: 'ToolIcon',
    route: 'apps-utilsTravelport',
  },
  // {
  //   title: 'Role',
  //   icon: 'KeyIcon',
  //   route: 'apps-role-list',
  //   action: 'authz.getCustomRole',
  //   visibleFloors: ['F1', 'F2'],
  // },
  {
    title: 'auditLog.title',
    icon: 'ClockIcon',
    route: 'apps-audit-log-list',
    // action: 'audit_log.readAuditlog',
    visibleFloors: ['F1'],
    visibleRoles: ['OPE', 'ADM'],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'My account',
        route: 'account-settings',
      },
      {
        title: 'Agency account',
        route: 'apps-agencySetting',
      },
      {
        title: 'Fee Configs',
        route: 'apps-configsFee',
        visibleFloors: ['F1', 'F2'],
      },
      {
        title: 'Service Fee Configs',
        route: 'apps-configsServiceFee',
      },
      {
        title: 'electronicTicket',
        route: 'apps-configsTicket',
      },
      {
        title: 'configsDisplay',
        route: 'apps-configsDisplay',
      },
    ],
  },
  {
    title: 'Send Notification',
    route: 'apps-configsNotification',
    icon: 'BellIcon',
    visibleFloors: ['F1'],
    visibleRoles: ['ADM', 'OPE'],
    // hideForProduction: true,
  },
  {
    title: 'sepayManager',
    icon: 'DollarSignIcon',
    visibleFloors: ['F1'],
    visibleRoles: ['KTT', 'KTV', 'ADM'],
    hideForProduction: true,
    children: [
      {
        title: 'Transactions',
        icon: 'FileTextIcon',
        route: 'apps-transaction',
      },
      {
        title: 'Bank accounts',
        icon: 'FileTextIcon',
        route: 'apps-bank-account',
      },
      {
        title: 'Companies',
        icon: 'FileTextIcon',
        route: 'apps-company',
      },
    ],
  },
  {
    title: 'Support Request',
    icon: 'ActivityIcon',
    visibleFloors: ['F1', 'F2'],
    route: 'apps-supports',
    hideForProduction: true,
  },
]
