var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    ref: "refNotificationDropdown",
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "id": "notification-dropdown",
      "menu-class": "dropdown-menu-media",
      "right": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": _vm.unReadCount,
            "badge-classes": "bg-danger badge-glow ".concat(_vm.isMobileView ? 'font-small-1' : ''),
            "icon": "BellIcon",
            "size": "20"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('li', {
    staticClass: "dropdown-menu-header"
  }, [_c('div', {
    staticClass: "dropdown-header d-flex-center py-50"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_vm._v(" Thông báo ")]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.unReadCount ? _c('b-badge', {
    staticClass: "my-25 pl-75 mr-1 d-flex-center text-airline",
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_c('span', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s("".concat(_vm.unReadCount, " Ch\u01B0a \u0111\u1ECDc")) + " ")])]) : _vm._e(), _vm.notificationData.length ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(0, 207, 232, 0.15)',
      expression: "'rgba(0, 207, 232, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "custom_class rounded-pill p-50",
    attrs: {
      "id": "notification-dropdown-header",
      "variant": "link",
      "toggle-class": "p-0",
      "right": "",
      "no-caret": "",
      "boundary": "viewport"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "align-middle text-body",
          attrs: {
            "icon": "MenuIcon",
            "size": "21"
          }
        })];
      },
      proxy: true
    }], null, false, 4001534423)
  }, [_c('b-dropdown-item', {
    attrs: {
      "id": "notification-dropdown-header-item",
      "disabled": !_vm.unReadCount || _vm.isLoadingReadAll
    },
    on: {
      "click": function click($event) {
        return _vm.handleReadAllNoti(_vm.notificationData);
      }
    }
  }, [_c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v(" Đánh dấu tất cả là đã đọc ")])])], 1)], 1) : _vm._e()], 1)])]), _c('div', {
    staticClass: "vh-45"
  }, [_vm.notificationData.length ? _c('div', {
    staticClass: "vh-100 mt-25"
  }, [_vm._l(_vm.notificationData, function (notification, index) {
    return _c('div', {
      key: index,
      staticClass: "card-noti d-flex"
    }, [_c('b-media', {
      staticClass: "py-50 d-flex aline-items-center flex-fill",
      on: {
        "click": function click($event) {
          return _vm.readNotiDetail(notification);
        }
      },
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-avatar', {
            attrs: {
              "size": "40",
              "src": _vm.appLogoImage,
              "variant": "light-success"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "d-flex-column"
    }, [_c('p', {
      staticClass: "font-weight-bolder media-heading"
    }, [_vm._v(" " + _vm._s(notification.subject) + " ")]), _c('small', {
      staticClass: "notification-text text-primary"
    }, [_c('b', [_vm._v(_vm._s(_vm.convertISODateTime(notification.sendTime).dateTime.replace(' | ', ', ')))])])])]), _c('div', {
      staticClass: "d-flex-center pr-25"
    }, [_c('b-dropdown', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(0, 207, 232, 0.15)',
        expression: "'rgba(0, 207, 232, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "rounded-pill p-50",
      attrs: {
        "id": "notification-dropdown-item",
        "variant": "link",
        "toggle-class": "p-0",
        "right": "",
        "no-caret": "",
        "boundary": "viewport"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('feather-icon', {
            staticClass: "align-middle text-body",
            attrs: {
              "icon": "MoreHorizontalIcon",
              "size": "16"
            },
            on: {
              "click": function click($event) {
                return _vm.handleGetNotiById(notification.notificationId);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "id": "notification-dropdown-item-item",
        "disabled": _vm.checkDisableReschedule(notification)
      },
      on: {
        "click": function click($event) {
          return _vm.handleToggleReSend(notification);
        }
      }
    }, [_c('span', {
      staticClass: "align-middle ml-50"
    }, [_vm._v(" " + _vm._s(_vm.isReschedule ? 'Tắt cảnh báo' : 'Bật cảnh báo') + " ")])])], 1)], 1)], 1);
  }), _c('infinite-loading', {
    on: {
      "infinite": _vm.infiniteHandler
    }
  }, [!_vm.notificationData.length ? _c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }) : _vm._e(), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }, [_vm._v(" Đã hiển thị toàn bộ thông báo ")])])], 2) : _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._v(" Không có thông báo ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }